<template>
  <div class="download-wrap ly-wrap">
    <h1>武隆项目</h1>
    <h3>智慧水务平台</h3>
    <a class="btn"
       @click="handleDown" href="http://yunsxk.com:8200/sys/common/static/temp/app.apk" download=""><i
        class="android"></i>安卓版下载</a>
    <div class="dw-bg">
      <div class="pop-wrap" v-show="showPop">
        <div class="pop-bg">
          <div class="finger"></div>
          <div class="pop-info">
            <p><span>1</span>点击右上角的 <i class="icon more"></i>按钮</p>
            <p><span>2</span>选择 <i class="icon ie"></i>在浏览器打开</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  computed: {},
  data() {
    return {
      showPop: false
    }
  },
  mounted() {

  },
  created() {
  },
  methods: {
    handleDown() {
      var is_weixin = (function () {
        return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
      })();
      if (is_weixin) {
        this.showPop = true
      } else {
        this.showPop = false
      }
    }
  },
  destroyed: function () {
  },
}
</script>

<style lang="less" scoped>
.download-wrap {
  width: 100%;
  height: 100%;
  background: url("../assets/img/download_img.png") no-repeat center;
  background-size: cover;
  position: relative;
  text-align: center;

  h1 {
    font-size: 0.8rem;
    color: #ffffff;
    margin: 0;
    padding-top: 12%;
    letter-spacing: 6px;
  }

  h3 {
    font-weight: normal;
    font-size: 0.4rem;
    margin: 0.2rem 0;
    color: #ffffff;
    letter-spacing: 5px;
  }

  .btn {
    width: 60%;
    height: 1rem;
    background-color: #ffffff;
    border-radius: 0.1rem;
    line-height: 1rem;
    color: #1890ff;
    box-shadow: 0 0.08rem 0.12rem 0 rgba(0, 136, 255, .6);
    position: absolute;
    bottom: 20%;
    left: 20%;
    font-size: 0.4rem;
    text-decoration: none;
    letter-spacing: 2px;
    i {
      display: inline-block;
      width: 0.64rem;
      height: 0.54rem;
      background: url("../assets/img/android.png") no-repeat center;
      background-size: 0.5rem;
      position: relative;
      top: 0.18rem;
      margin-right: 0.1rem;
    }
  }

  .dw-bg {
    width: 100%;
    height: 1.8rem;
    background: url("../assets/img/dw-bg.png") no-repeat center;
    background-size: cover;
    position: absolute;
    bottom: 0;
  }


  .pop-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


    .pop-bg {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .8);

      .finger {
        width: 0.55rem;
        height: 0.73rem;
        background: url("../assets/img/finger.png") no-repeat center;
        background-size: contain;
        position: absolute;
        right: 0.2rem;
        top: 0.3rem;
        animation: dong 1s infinite;
      }

      .pop-info {
        padding-top: 30%;

        p {
          font-size: 0.36rem;
          color: #ffffff;
          line-height: 3;
        }

        span {
          display: inline-block;
          padding: 0.2rem;
          background-color: #0088FF;
          border-radius: 99%;
          line-height: 0.2rem;
          margin-right: 0.1rem;
        }

        .icon {
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          position: relative;
          top: 0.1rem;
          background: url("../assets/img/more.png") no-repeat center;
          background-size: contain;
          margin-right: 0.1rem;
        }

        .ie {
          background: url("../assets/img/ie.png") no-repeat center;
          background-size: contain;
        }
      }

    }

  }

}

@keyframes dong {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>
